import Vue from "vue";
import VueRouter from "vue-router";
import SignIn from "../views/SignIn.vue";
// group
import Group_Info from "../views/Group_Info.vue";
import Group_Devices from "../views/Group_Devices.vue";
import Group_Alert from "../views/Group_Alert.vue";
import Group_Users from "../views/Group_Users.vue";
import Group_Mails from "../views/Group_Mails.vue";
// manage
import Manage_Joinselect from "../views/Manage_Joinselect.vue";
import Manage_Account from "../views/Manage_Account.vue";
import Manage_Users from "../views/Manage_Users.vue";
import Manage_Groups from "../views/Manage_Groups.vue";
import Manage_Devices from "../views/Manage_Devices.vue";
import Manage_Organizations from "../views/Manage_Organizations.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SignIn",
    component: SignIn
  },
  // group
  {
    path: "/group/:group_id",
    name: "group_info",
    component: Group_Info
  },
  {
    path: "/group/devices/:group_id",
    name: "group_devices",
    component: Group_Devices
  },
  {
    path: "/group/alerts/:group_id",
    name: "group_alerts",
    component: Group_Alert
  },
  {
    path: "/groups/users/:group_id",
    name: "group_users",
    component: Group_Users
  },
  {
    path: "/groups/mails/:group_id",
    name: "group_mails",
    component: Group_Mails
  },
  // manage
  {
    path: "/manage/joinselect",
    name: "manage_joinselect",
    component: Manage_Joinselect
  },
  {
    path: "/manage/account",
    name: "manage_account",
    component: Manage_Account
  },
  {
    path: "/manage/users",
    name: "manage_users",
    component: Manage_Users
  },
  {
    path: "/manage/groups",
    name: "manage_groups",
    component: Manage_Groups
  },
  {
    path: "/manage/devices",
    name: "manage_devices",
    component: Manage_Devices
  },
  {
    path: "/manage/organizations",
    name: "manage_organizations",
    component: Manage_Organizations
  }
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
