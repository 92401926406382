<template>
  <v-btn
    id="edit_btn"
    class="mx-2"
    small
    fab
    :plain="plain"
    :color="color"
    :elevation="elevation"
    @click="$emit('edit')"
  >
    <v-icon color="black">
      mdi-folder-cog-outline
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "edit_btn",
  props: {
    plain: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    },
    elevation: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style>
.add_btn {
  position: fixed;
  right: 50px;
  bottom: 50px;
  transition: 1s;
  opacity: 0.7;
}
</style>
