<template>
  <div id="join_select">
    <HeaderFrame :nav_item="header_items" />
    <v-main>
      <v-container style="mb-3">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title>所属グループ</v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      md="3"
                      sm="12"
                      v-for="(item, group_id) in groups"
                      :key="group_id"
                    >
                      <cardFrame
                        :card_title="item.name"
                        @click="enter_group(group_id)"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import HeaderFrame from "../components/header.vue";
import constants from "../common/constants";
import cardFrame from "../components/card.vue";
export default {
  name: "join_select",
  components: {
    HeaderFrame,
    cardFrame
  },
  computed: {
    header_items() {
      return constants.mangage_header[this.role];
    },
    role() {
      return this.$store.state.user !== null ? this.$store.state.user.role : 1;
    }
  },
  data() {
    return {
      groups: {}
    };
  },
  methods: {
    async enter_group(group_id) {
      console.log(group_id);
      const group = await this.$api.get_group(
        this.$store.state.token.access_token,
        group_id
      );
      this.$store.commit("set_group", group[group_id]);
      this.$router.push({
        name: "group_info",
        params: { group_id: group_id }
      });
    },
    enter_manage(to) {
      console.log(to);
      this.$router.push(to);
    }
  },
  async created() {
    setTimeout(async () => {
      // groups 取得
      const groups = await this.$api.get_joined_group(
        this.$store.state.token.access_token
      );
      this.$set(this, "groups", groups);
    }, 500);
  }
};
</script>

<style></style>
