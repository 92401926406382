export async function get_accesstoken(db) {
  return db.getData_all("key").then(async data => {
    return data[0]["access_token"];
  });
}

export async function refreash_token(db, api) {
  console.log("@refreash_token");
  const key_data = await db.getData_all("key");
  const data = await api.refresh(key_data[0]["refresh_token"]);
  db.setData_all("key", {
    user_id: key_data[0]["user_id"],
    ...data
  });
}
