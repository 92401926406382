<template>
  <v-card width="400px" class="mx-auto mt-5">
    <v-card-title>{{ title }}</v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field
          :rules="[rules.required]"
          v-model="user_id"
          label="ユーザーID"
          required
        />
        <v-text-field
          v-model="password"
          :append-icon="password_show_type ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required]"
          :type="password_show_type ? 'text' : 'password'"
          label="パスワード"
          required
          @click:append="change_show"
          @keydown.enter="sign_in"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-container fluid>
        <v-row>
          <v-spacer />
          <v-btn @click="sign_in" :disabled="btn_flag">
            Sign In
            <v-progress-circular indeterminate v-if="btn_flag"
          /></v-btn>
        </v-row>
      </v-container>
    </v-card-actions>
    <Toast ref="toast" />
  </v-card>
</template>

<script>
import constants from "../common/constants";
import Toast from "./toast.vue";

export default {
  name: "sign_in",
  components: { Toast },
  data() {
    return {
      title: constants.system_name,
      password_show_type: false,
      user_id: "",
      password: "",
      btn_flag: false,
      rules: {
        required: value => !!value || "Required.",
        min: v => v.length >= 4 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`
      }
    };
  },
  methods: {
    async sign_in() {
      this.$set(this, "btn_flag", true);
      await this.$api
        .authorization(this.user_id, this.password)
        .then(async data => {
          if (Object.keys(data).length != 0) {
            // TODO: 削除予定
            console.log("true");
            this.$db.deleteData_all("key");
            this.$db.setData_all("key", {
              user_id: this.user_id,
              ...data
            });
            // NOTE: access tokenを取得し、vuexに保存
            this.$store.commit("set_token", {
              user_id: this.user_id,
              ...data
            });
            // user情報取得
            let user = await this.$api.get_login_user(data["access_token"]);
            delete user[this.user_id].password;
            this.$store.commit("set_user", user[this.user_id]);

            this.$router.push({ name: "manage_joinselect" });
          } else {
            this.$refs["toast"].showToast("サインインに失敗しました");
          }
          this.$set(this, "btn_flag", false);
        });
    },
    change_show() {
      this.$set(this, "password_show_type", !this.password_show_type);
    }
  },
  beforeMount() {
    this.$db.deleteData_all("key");
  }
};
</script>

<style></style>
