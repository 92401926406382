<template>
  <v-container id="tableFrame" class="pt-0 pb-0">
    <v-row>
      <v-col cols="12">
        <AddBtn v-if="add_flag" @add="$emit('add')" />
        <DeleteBtn v-if="delete_flag" @remove="$emit('remove')" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="datasets"
          :item-key="item_key"
          :search="search"
          :sort-by="sort_by"
          :sort-desc="sort_desc"
          show-select
          class="elevation-1"
          @click:row="
            data => {
              $emit('row_click', data);
            }
          "
        >
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddBtn from "./add_btn.vue";
import DeleteBtn from "./delete_btn.vue";
export default {
  name: "tableFrame",
  components: {
    AddBtn,
    DeleteBtn
  },
  props: {
    headers: {
      type: Array,
      default() {
        return [];
      }
    },
    item_key: {
      type: String,
      default() {
        return "id";
      }
    },
    add_flag: {
      type: Boolean,
      default: true
    },
    delete_flag: {
      type: Boolean,
      default: true
    },
    Dataset: {
      type: Object,
      default() {
        return {};
      }
    },
    Selected: {
      type: Array,
      default() {
        return [];
      }
    },
    Sort_by: {
      type: Array,
      default() {
        return [];
      }
    },
    Sort_desc: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    Selected(v) {
      this.selected = v;
    },
    Sort_by(v) {
      this.sort_by = v;
    },
    Sort_desc(v) {
      this.sort_desc = v;
    }
  },
  computed: {
    datasets() {
      return Object.entries(this.Dataset).map(([key, value]) => ({
        id: key,
        ...value
      }));
    }
  },
  data() {
    return {
      selected: this.Selected,
      search: "",
      sort_by: this.Sort_by,
      sort_desc: this.Sort_desc
    };
  },
  methods: {
    get_selected() {
      return this.selected;
    }
  }
};
</script>

<style></style>
