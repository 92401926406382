var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
    { text: 'カメラID', value: 'id' },
    { text: 'カメラ名', value: 'name' },
    { text: '設置日', value: 'date', align: 'center' },
    { text: '更新日', value: 'recent', align: 'center' },
    { text: 'ステータス', value: 'status', align: 'center' },
    { text: '詳細', value: 'edit', align: 'center' }
  ],"items":_vm.dataset_table.map(function (value) { return ({
      id: value.id,
      name: value.name,
      date: value.date,
      regular_time: value.regular_time,
      position: value.position,
      endpoint_email: value.endpoint_email != undefined,
      recent:
        Object.keys(value.recent).length != 0
          ? Object.entries(value.recent)[0][1].date
          : 'データなし'
    }); }),"item-key":_vm.table.item_key,"search":_vm.search,"id":"device_table"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.icon_color(item)}},[_vm._v(_vm._s(_vm.icon(item)))])]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('EditBtn',{attrs:{"plain":true},on:{"edit":function($event){return _vm.$emit('edit', item)}}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }