<template>
  <div id="roleframe">
    <v-row>
      <v-col cols="12">
        <AddBtn @add="add_dialog(true)" />
        <DeleteBtn @remove="remove" />
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="table.headers"
          :items="datasets"
          :item-key="table.item_key"
          :search="search"
          show-select
          class="elevation-1"
          @click:row="data => row_click(data)"
        >
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <span v-if="item.role == 0">管理者</span>
            <span v-if="item.role == 1">編集者</span>
            <span v-if="item.role == 2">閲覧者</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <UserRole
      ref="add_form"
      :edit_flag="false"
      @click="add"
      :edit_enable="edit_enable"
    />
    <UserRole
      ref="edit_form"
      :edit_flag="true"
      @click="edit"
      :edit_enable="edit_enable"
    />
  </div>
</template>

<script>
import AddBtn from "./add_btn.vue";
import DeleteBtn from "./delete_btn.vue";
import UserRole from "./user_role_form.vue";
export default {
  name: "roleframe",
  components: {
    AddBtn,
    DeleteBtn,
    UserRole
  },
  props: {
    admin_flag: {
      type: Boolean,
      default: false
    },
    edit_enable: {
      type: Boolean,
      default: false
    },
    Datasorce: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    Datasorce(v) {
      this.datasorce = v;
    }
  },
  computed: {
    datasets() {
      let roles = {};
      for (let key in this.datasorce.role) {
        roles[key] = {
          role: this.datasorce.role[key]
        };
      }
      return Object.entries(roles).map(([key, value]) => ({
        id: key,
        ...value
      }));
    }
  },
  data() {
    return {
      table: {
        headers: [
          { text: "ユーザーID", value: "id" },
          { text: "役割", value: "role", align: "center" }
        ],
        item_key: "id"
      },
      selected: [],
      search: "",
      datasorce: this.Datasorce
    };
  },
  methods: {
    add_dialog(bool) {
      this.$set(this.$refs["add_form"], "dialog", bool);
    },
    edit_dialog(bool) {
      this.$set(this.$refs["edit_form"], "dialog", bool);
    },
    row_click(data) {
      let tmp = {};
      Object.assign(tmp, data);
      this.$set(this.$refs["edit_form"], "user", tmp);
      this.edit_dialog(true);
    },
    add(data) {
      let datasorce = this.datasorce;
      this.$set(this.datasorce.role, data.id, data.role);
      datasorce.role[data.id] = data.role;
      console.log("@add", datasorce);
      this.$emit("add", datasorce);
      this.add_dialog(false);
      this.$refs["add_form"].init();
    },
    edit(data) {
      let datasorce = this.datasorce;
      this.$set(this.datasorce.role, data.id, data.role);
      datasorce.role[data.id] = data.role;
      console.log("@edit", datasorce);
      this.$emit("edit", datasorce);
      this.edit_dialog(false);
    },
    remove() {
      let datasorce = this.datasorce;
      for (let data of this.selected) {
        console.log(data);
        this.$delete(this.datasorce.role, data.id);
        delete datasorce.role[data.id];
      }
      this.$emit("remove", datasorce);
      this.$set(this, "selected", []);
    }
  }
};
</script>

<style></style>
