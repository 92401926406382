<template>
  <v-snackbar v-model="snackbar" id="toast">
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "toast",
  props: {},
  data() {
    return {
      snackbar: false,
      text: ""
    };
  },
  methods: {
    showToast(text) {
      this.$set(this, "text", text);
      this.$set(this, "snackbar", true);
    }
  }
};
</script>

<style></style>
