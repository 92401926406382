<template>
  <v-container id="users_for_manage">
    <v-row>
      <v-col cols="12">
        <UserFrame
          ref="usertable"
          :Datasorce="group"
          :admin_flag="admin_flag"
          :edit_enable="edit_enable"
          @add="edit"
          @edit="edit"
          @remove="edit"
        />
      </v-col>
    </v-row>
    <Toast ref="toast" />
  </v-container>
</template>

<script>
import UserFrame from "./role_table.vue";
import Toast from "./toast.vue";
export default {
  name: "users_for_manage",
  components: {
    UserFrame,
    Toast
  },
  computed: {
    datasets() {
      let roles = {};
      for (let key in this.group.role) {
        roles[key] = {
          role: this.group.role[key]
        };
      }
      return roles;
    },
    group: {
      get() {
        return this.$store.state.group != null ? this.$store.state.group : {};
      },
      set(v) {
        this.$store.commit("set_group", v);
      }
    }
  },
  data() {
    return {
      admin_flag: false,
      edit_enable: false,
      group_id: this.$route.params.group_id
    };
  },
  methods: {
    async set_data(data) {
      this.$set(this, "group", data);
    },
    async edit(data) {
      console.log("@edit", data);
      const result = await this.$api.set_group(
        this.$store.state.token.access_token,
        this.$route.params.group_id,
        data.name,
        data.address,
        data.role
      );
      if (result) {
        this.$refs["toast"].showToast("変更しました");
        this.set_data(data);
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
    },
    role_check() {
      const group = this.$store.state.group;
      const role = this.$store.state.user.role;
      const user_id = this.$store.state.token.user_id;

      try {
        if (role == 0) {
          this.$set(this, "admin_flag", true);
          this.$set(this, "edit_enable", true);
        } else {
          if (group.role[user_id] == 0) {
            this.$set(this, "admin_flag", true);
            this.$set(this, "edit_enable", true);
          } else if (group.role[user_id] == 1) {
            this.$set(this, "admin_flag", false);
            this.$set(this, "edit_enable", true);
          } else if (group.role[user_id] == 2) {
            this.$set(this, "admin_flag", false);
            this.$set(this, "edit_enable", false);
          }
        }
      } catch {
        this.$router.push({ name: "SignIn" });
      }
    }
  },
  async beforeMount() {
    setTimeout(async () => {
      this.role_check();
    }, 500);
  }
};
</script>

<style></style>
