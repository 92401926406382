export default {
  nav_color: "primary",
  system_name: "あいわなクラウド",
  nav_item: [
    { text: "item1", to: "/" },
    { text: "item2", to: "/signin" }
  ],
  footer_color: "primary",
  footer_text: "Copyright 株式会社日本遮蔽技研 2021. All right reserved",
  sign_up_enable: false,
  mangage_header: {
    0: [
      {
        text: "所属",
        to: {
          name: "manage_joinselect"
        }
      },
      {
        text: "アカウント設定",
        to: {
          name: "manage_account"
        }
      },
      {
        text: "組織",
        to: {
          name: "manage_organizations"
        }
      },
      {
        text: "カメラ",
        to: {
          name: "manage_devices"
        }
      },
      {
        text: "ユーザ",
        to: {
          name: "manage_users"
        }
      }
    ],
    1: [
      {
        text: "所属",
        to: {
          name: "manage_joinselect"
        }
      },
      {
        text: "アカウント設定",
        to: {
          name: "manage_account"
        }
      }
    ]
  },
  group_header(group_id) {
    return [
      {
        text: "設定",
        to: {
          name: "group_info",
          params: { group_id: group_id }
        }
      },
      {
        text: "カメラ",
        to: {
          name: "group_devices",
          params: { group_id: group_id }
        }
      },
      {
        text: "アラート",
        to: {
          name: "group_alerts",
          params: { group_id: group_id }
        }
      },
      {
        text: "ユーザ",
        to: {
          name: "group_users",
          params: { group_id: group_id }
        }
      },
      {
        text: "メール",
        to: {
          name: "group_mails",
          params: { group_id: group_id }
        }
      }
    ];
  }
};
