<template>
  <v-container id="organization_for_manage">
    <v-row>
      <v-col cols="12" class="mb-10">
        <OrganizationTable
          v-if="form_mode == ''"
          :admin_flag="admin_flag"
          :edit_enable="edit_enable"
          :Organizations="organizations"
          @add="form_mode = 'add_organization'"
          @edit="move_edit_form"
          @remove="remove_organization"
        />
        <OrganizationForm
          v-if="form_mode == 'add_organization'"
          ref="add_organization_form"
          :edit_flag="false"
          :edit_enable="true"
          :Users="users"
          :Devices="devices"
          @click="add_organization"
          @cancel="form_mode = ''"
        />
        <OrganizationForm
          v-if="form_mode == 'edit_organization'"
          :edit_flag="true"
          :edit_enable="true"
          :Organization="selected_organization"
          :Users="users"
          :Devices="devices"
          @click="edit_organization"
          @cancel="form_mode = ''"
          @add_user="add_user_dialog(true)"
          @add_group="add_group_dialog(true)"
          @remove_group="remove_group"
          @move="
            group_id => {
              move(group_id);
            }
          "
        />
      </v-col>
    </v-row>
    <GroupInfo
      ref="add_group"
      :edit_flag="false"
      :edit_enable="true"
      @click="add_group"
    />
    <UserInfo ref="add_user" :edit_flag="false" @click="add_user" />

    <Toast ref="toast" />
  </v-container>
</template>

<script>
import OrganizationTable from "./organization_table.vue";
import OrganizationForm from "./organization_form.vue";
import UserInfo from "./organization_user_form.vue";
import GroupInfo from "./group_info_dialog.vue";
import Toast from "./toast.vue";
export default {
  name: "organization_for_manage",
  components: {
    OrganizationTable,
    OrganizationForm,
    UserInfo,
    GroupInfo,
    Toast
  },
  data() {
    return {
      form_mode: "",
      admin_flag: true,
      edit_enable: true,
      organizations: {},
      users: {},
      devices: {},
      selected_organization: {
        id: "",
        name: "",
        description: "",
        groups: {},
        users: [],
        devices: []
      }
    };
  },
  methods: {
    add_user_dialog(bool) {
      this.$set(this.$refs["add_user"], "dialog", bool);
    },
    add_group_dialog(bool) {
      this.$set(this.$refs["add_group"], "dialog", bool);
    },
    async move_edit_form(data) {
      console.log("@move_edit_form");
      let v = {};
      Object.assign(v, data);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      v.groups = await this.$api.get_organization_group(acceess_token, v.id);
      console.log(v.groups);
      this.$set(this, "selected_organization", v);
      this.$set(this, "form_mode", "edit_organization");
    },
    async add_organization(value) {
      console.log("@add_organization", value);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.add_organization(
        acceess_token,
        value.name,
        value.description,
        value.users,
        value.devices
      );
      console.log(result);
      if (result.organization_id != undefined) {
        value.id = result.organization_id;
        this.set_organization(value);
        this.$refs["toast"].showToast("追加しました");
        this.$set(this, "form_mode", "");
      } else {
        this.$refs["toast"].showToast("追加に失敗しました");
      }
    },
    async edit_organization(value) {
      console.log("@edit_organization", value);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.set_organization(
        acceess_token,
        value.id,
        value.name,
        value.description,
        value.users,
        value.devices
      );
      console.log(result);
      if (result) {
        this.set_organization(value);
        this.$refs["toast"].showToast("変更しました");
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
    },
    async remove_organization(selected) {
      console.log("@remove_organization", selected);
      if (selected.length == 0) {
        this.$$refs["toast"].showToast("一つ以上選択してください");
      } else {
        for (let data of selected) {
          console.log(data);
          const acceess_token = await this.$fnc.get_accesstoken(this.$db);
          const exe = async () => {
            const result = await this.$api.delete_organization(
              acceess_token,
              data.id
            );
            if (result) {
              this.$delete(this.organizations, data.id);
            }
          };
          exe();
        }
        this.$refs["toast"].showToast("削除しました");
      }
    },
    async add_user(value) {
      console.log("@add_user", value);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.add_organization_user(
        acceess_token,
        this.selected_organization.id,
        value.id,
        value.name,
        value.password,
        value.email
      );
      console.log(result);
      if (result.user_id != undefined) {
        this.selected_organization.users.push(result.user_id);
        this.organizations[this.selected_organization.id].users.push(
          result.user_id
        );
        this.set_user(value);
        this.$refs["toast"].showToast("追加しました");
        this.$refs["add_user"].init();
      } else {
        this.$refs["toast"].showToast("追加に失敗しました");
      }
      this.add_user_dialog(false);
    },
    async add_group(value) {
      console.log("@add_group", value);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.add_organization_group(
        acceess_token,
        this.selected_organization.id,
        value.name,
        value.address
      );
      console.log(result);
      if (result.group_id != undefined) {
        value.id = result.group_id;
        this.set_group(value);
        this.$refs["toast"].showToast("追加しました");
        this.$refs["add_group"].init();
      } else {
        this.$refs["toast"].showToast("追加に失敗しました");
      }
      this.add_group_dialog(false);
    },
    async remove_group(selected) {
      if (selected.length == 0) {
        this.$refs["toast"].showToast("一つ以上選択してください");
      } else {
        for (let data of selected) {
          console.log(data);
          const acceess_token = await this.$fnc.get_accesstoken(this.$db);
          const exe = async () => {
            const result = await this.$api.delete_group(acceess_token, data.id);
            if (result) {
              this.$delete(this.selected_organization.groups, data.id);
            }
          };
          exe();
        }
      }
    },
    set_organization(value) {
      this.$set(this.organizations, value.id, {
        name: value.name,
        description: value.description,
        users: value.users,
        devices: value.devices,
        spot_limit: value.spot_limit
      });
    },
    set_user(value) {
      this.$set(this.users, value.id, {
        name: value.name,
        password: value.password,
        email: value.email,
        role: value.role
      });
    },
    set_group(value) {
      this.$set(this.selected_organization.groups, value.id, {
        name: value.name,
        address: value.address,
        organization_id: this.selected_organization.id,
        role: {},
        users: []
      });
    },
    async move(group_id) {
      const group = await this.$api.get_group(
        this.$store.state.token.access_token,
        group_id
      );
      this.$store.commit("set_group", group[group_id]);
      this.$router.push({
        name: "group_info",
        params: { group_id: group_id }
      });
    }
  },
  async beforeMount() {
    const acceess_token = await this.$fnc.get_accesstoken(this.$db);
    const organizations = await this.$api.get_organization(acceess_token);
    this.$set(this, "organizations", organizations);

    const users = await this.$api.get_user_list(acceess_token);
    const devices = await this.$api.get_device_list(acceess_token);
    this.$set(this, "users", users);
    this.$set(this, "devices", devices);
  }
};
</script>

<style></style>
