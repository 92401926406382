<template>
  <div id="group_info">
    <HeaderFrame :nav_item="header_items" />
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <Groupinfo
              :edit_flag="true"
              :edit_enable="edit_enable"
              :group="group"
              @click="edit"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Toast ref="toast" />
  </div>
</template>

<script>
import HeaderFrame from "../components/header.vue";
import Groupinfo from "../components/group_info.vue";
import Toast from "../components/toast.vue";
import constants from "../common/constants";
export default {
  name: "group_info",
  components: {
    HeaderFrame,
    Groupinfo,
    Toast
  },
  computed: {
    header_items() {
      return constants.group_header(this.$route.params.group_id);
    }
  },
  data() {
    return {
      group_id: this.$route.params.group_id,
      edit_enable: false,
      group: {
        id: "",
        name: "",
        address: "",
        role: {}
      }
    };
  },
  methods: {
    async edit(data) {
      console.log(this.user);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.set_group(
        acceess_token,
        data.id,
        data.name,
        data.address,
        data.role
      );
      if (result) {
        this.$refs["toast"].showToast("変更しました");
        this.$set(this, "group", data);
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
    },
    async role_check(acceess_token, group) {
      let user = await this.$api.get_login_user(acceess_token);
      let user_ids = Object.keys(user);
      try {
        if (user[user_ids[0]].role == 0) {
          this.$set(this, "edit_enable", true);
        } else {
          if (group.role[user_ids[0]] == 0) {
            this.$set(this, "edit_enable", true);
          } else if (group.role[user_ids[0]] == 1) {
            this.$set(this, "edit_enable", true);
          } else if (group.role[user_ids[0]] == 2) {
            this.$set(this, "edit_enable", false);
          }
        }
      } catch {
        this.$router.push({ name: "SignIn" });
      }
    }
  },
  async beforeCreate() {
    const group_id = this.$route.params.group_id;
    this.$set(this, "group_id", group_id);
    const acceess_token = await this.$fnc.get_accesstoken(this.$db);
    const group = await this.$api.get_group(acceess_token, group_id);
    this.role_check(acceess_token, group[group_id]);
    this.$set(this, "group", {
      id: group_id,
      ...group[group_id]
    });
  }
};
</script>

<style></style>
