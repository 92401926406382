<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn color="error" icon v-bind="attrs" v-on="on">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </span>
          </template>
          <span>削除</span>
        </v-tooltip>
      </span>
    </template>
    <v-card>
      <v-card-title>削除確認</v-card-title>
      <v-card-text>本当によろしいですか？</v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="error" @click="click" text>OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "delete_btn",
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    click() {
      console.log("@click");
      this.$emit("delete", this.item);
      this.$set(this, "dialog", false);
    }
  }
};
</script>

<style></style>
