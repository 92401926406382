<template>
  <v-container id="devices_for_manage" class="mb-3">
    <v-row>
      <v-col cols="12">
        <v-select v-model="show_type" :items="type_list" label="表示形式" />
      </v-col>
      <v-col cols="12">
        <AddBtn @add="show_dialog({}, 'add')" />
      </v-col>
      <v-col cols="12">
        <span v-if="show_type == 'マップ'">
          <DeviceMap
            :dataset_table="datasets"
            @marker_click="data => show_dialog(data, 'edit')"
          />
        </span>
        <span v-if="show_type == '表'">
          <DeviceTable
            :dataset_table="datasets"
            @edit="data => show_dialog(data, 'edit')"
          />
        </span>
      </v-col>
    </v-row>
    <Toast ref="toast" />
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span v-if="edit_flag">変更</span>
            <span v-else>追加</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="ID"
                  :disabled="edit_flag"
                  v-model="selected_device.id"
                />
                <v-text-field label="Name" v-model="selected_device.name" />
                <v-text-field
                  label="エンドポイントEmail"
                  v-model="selected_device.endpoint_email"
                />
                <v-menu
                  v-model="date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected_device.date"
                      label="設置日"
                      hint="YYYY-MM-DD format"
                      persistent-hint
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selected_device.date"
                    locale="ja"
                    scrollable
                    no-title
                    @input="date_menu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                  v-model="selected_device.time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="selected_device.regular_time"
                      label="定期受信時刻"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="selected_device.time_menu"
                    v-model="selected_device.regular_time"
                    format="24hr"
                    full-width
                  ></v-time-picker>
                </v-menu>
                <v-textarea
                  label="備考"
                  v-model="selected_device.description"
                />
              </v-col>
              <v-col cols="12">
                <DeviceData
                  v-if="edit_flag"
                  :image_dialog_src="image_dialog_src"
                  :Devicedata="devicedata"
                  :is_delete="true"
                  @image_show="image_show"
                  @download_image="download_image"
                  @delete="delete_data"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="緯度"
                  type="number"
                  @change="selected_device_position_lat"
                  v-model.number="selected_device.position.lat"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="経度"
                  type="number"
                  @change="selected_device_position_lng"
                  v-model.number="selected_device.position.lng"
                />
              </v-col>
              <v-col cols="12">
                <GmapMap
                  id="mapFrame"
                  :center="edit_map_option.center"
                  :zoom="edit_map_option.zoom"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false
                  }"
                  style="width: 100%; height: 700px"
                  map-type-id="hybrid"
                  @click="map_clicked"
                >
                  <GmapMarker
                    @v-if="selected_device.position != undefined"
                    :position="selected_device.position"
                    :clickable="false"
                    :draggable="true"
                    @dragend="marker_draged"
                  >
                  </GmapMarker>
                </GmapMap>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-dialog v-if="edit_flag" v-model="delete_dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" color="error" v-bind="attrs" v-on="on"
                >削除</v-btn
              >
            </template>
            <v-card>
              <v-card-title>削除確認</v-card-title>
              <v-card-text>本当によろしいですか？</v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="error" @click="remove" text>OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn v-if="edit_flag" color="secondary" @click="edit">
            変更
          </v-btn>
          <v-btn v-else color="secondary" @click="add">
            追加
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DeviceTable from "./device_table.vue";
import DeviceMap from "./device_map.vue";
import DeviceData from "./device_data.vue";
import AddBtn from "./add_btn.vue";
import Toast from "./toast.vue";
export default {
  name: "devices_for_manage",
  components: {
    DeviceTable,
    DeviceMap,
    DeviceData,
    AddBtn,
    Toast
  },
  computed: {
    datasets() {
      return Object.entries(this.devices).map(([key, value]) => ({
        id: key,
        ...value
      }));
    }
  },
  data() {
    return {
      show_type: "表",
      type_list: ["表", "マップ"],
      image_dialog_src: "",
      edit_map_option: {
        center: { lat: 36, lng: 138 },
        zoom: 15
      },
      dialog: false,
      delete_dialog: false,
      edit_flag: false,
      date_menu: false,
      time_menu: false,
      devices: {},
      selected_device: {
        id: "",
        name: "",
        description: "",
        date: new Date().toISOString().substr(0, 10),
        regular_time: "10:00",
        position: { lat: 36, lng: 138 },
        endpoint_email: ""
      },
      devicedata: {}
    };
  },
  methods: {
    show_dialog(data, form_type) {
      if (form_type == "add") {
        this.$set(this, "edit_flag", false);
        this.$set(this, "edit_map_option", {
          center: { lat: 36, lng: 138 },
          zoom: 4
        });
        this.$set(this, "selected_device", {
          id: "",
          name: "",
          description: "",
          date: new Date().toISOString().substr(0, 10),
          regular_time: "10:00",
          position: { lat: 36, lng: 138 },
          endpoint_email: ""
        });
      } else if (form_type == "edit") {
        this.$set(this, "edit_flag", true);

        this.$set(this, "edit_map_option", {
          center: data.position,
          zoom: 15
        });
        this.set_device_data(data.id);
        this.$set(this, "selected_device", {
          id: data.id,
          ...this.devices[data.id]
        });
      }
      this.$set(this, "dialog", true);
    },
    async selected_device_position_lat(value) {
      this.$set(this.selected_device.position, "lat", value);
    },
    async selected_device_position_lng(value) {
      this.$set(this.selected_device.position, "lng", value);
    },
    async image_show(data) {
      console.log(data);
      const image_src = await this.$api.get_blob(
        this.$store.state.token.access_token,
        data.id
      );
      const blob_url = URL.createObjectURL(image_src);
      this.$set(this, "image_dialog_src", blob_url);
    },
    async download_image(data) {
      console.log(data);
      let a = document.createElement("a");
      a.href = data.blob;
      a.download = `${data.id}.jpeg`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      this.$refs["toast"].showToast(
        "ダウンロード中です。しばらくお待ちください。"
      );
    },
    async set_device_data(device_id) {
      const devicedata = await this.$api.get_device_data(
        this.$store.state.token.access_token,
        device_id
      );
      this.$set(this, "devicedata", devicedata);
    },
    async set_data(data) {
      this.$set(this.devices, data.id, {
        name: data.name,
        type: data.type,
        description: data.description,
        date: data.date,
        regular_time: data.regular_time,
        position: data.position,
        endpoint_email: data.endpoint_email,
        recent: data.recent
      });
    },
    async remove() {
      try {
        this.$refs["toast"].showToast("削除中");
        console.log(this.selected_device);
        const result = await this.$api.delete_device(
          this.$store.state.token.access_token,
          this.selected_device.id
        );
        if (result) {
          this.$delete(this.devices, this.selected_device.id);
          this.$set(this, "delete_dialog", false);
          this.$set(this, "dialog", false);
          this.$refs["toast"].showToast("削除しました");
        } else {
          this.$refs["toast"].showToast("削除に失敗しました");
        }
      } catch {
        this.$refs["toast"].showToast("エラーが発生しました");
      }
    },
    async add() {
      console.log("@add", this.selected_device);
      const result = await this.$api.add_device(
        this.$store.state.token.access_token,
        this.selected_device.id,
        this.selected_device.name,
        this.selected_device.description,
        this.selected_device.date,
        this.selected_device.regular_time,
        this.selected_device.position,
        this.selected_device.endpoint_email
      );
      console.log(result);
      if (Object.keys(result).length) {
        this.$refs["toast"].showToast("追加しました");
        this.$set(this.selected_device, "id", result["device_id"]);
        this.$set(this.selected_device, "recent", []);
        this.set_data(this.selected_device);
        this.$set(this, "dialog", false);
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
    },
    async edit() {
      console.log("@edit", this.selected_device);
      const result = await this.$api.set_device(
        this.$store.state.token.access_token,
        this.selected_device.id,
        this.selected_device.name,
        this.selected_device.description,
        this.selected_device.date,
        this.selected_device.regular_time,
        this.selected_device.position,
        this.selected_device.endpoint_email
      );

      if (result) {
        this.$refs["toast"].showToast("変更しました");
        this.set_data(this.selected_device);
        this.$set(this, "dialog", false);
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
    },
    async delete_data(item) {
      console.log(item);
      console.log("@edit", this.selected_device);
      const result = await this.$api.delete_data(
        this.$store.state.token.access_token,
        item.id
      );
      if (result) {
        this.$refs["toast"].showToast("データを削除しました");
        this.$delete(this.devicedata, item.id);
      } else {
        this.$refs["toast"].showToast("データの削除に失敗しました");
      }
    },
    map_clicked(event) {
      this.$set(this.selected_device, "position", {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      });
    },
    marker_draged(event) {
      this.$set(this.selected_device, "position", {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      });
    }
  },
  async created() {
    setTimeout(async () => {
      const devices = await this.$api.get_device_list(
        this.$store.state.token.access_token
      );
      this.$set(this, "devices", devices);
    }, 500);
  }
};
</script>

<style></style>
