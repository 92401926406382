<template>
  <GmapMap
    class="mb-3"
    id="mapFrame"
    :center="map_option.center"
    :zoom="map_option.zoom"
    :options="{
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: false
    }"
    style="width: 100%; height: 700px"
    map-type-id="hybrid"
  >
    <GmapMarker
      v-for="value of dataset_table"
      :key="value.id"
      :position="value.position"
      :clickable="true"
      :draggable="false"
      @click="marker_click(value)"
    >
    </GmapMarker>
  </GmapMap>
</template>

<script>
export default {
  name: "device_map",
  props: {
    dataset_table: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  watch: {
    Map_option(v) {
      this.map_option = v;
    },
    Device(v) {
      this.device = v;
    }
  },
  data() {
    return {
      map_option: {
        center: { lat: 36, lng: 138 },
        zoom: 4
      },
      now: new Date()
    };
  },
  methods: {
    marker_click(value) {
      this.$emit("marker_click", value);
    },
    get_status(item) {
      const now_time =
        ("0" + this.now.getHours()).slice(-2) +
        ":" +
        ("0" + this.now.getMinutes()).slice(-2);
      const now_date = this.now.toISOString().substr(0, 10);
      let status = 2;

      if (Object.keys(item.recent).length != 0) {
        //データ有
        if (now_time >= item.regular_time) {
          //現在時刻が定期実行時刻後
          let recent_date_time = Object.entries(item.recent)[0][1].date.split(
            " "
          );
          const recent_date = recent_date_time[0].substr(0, 10);
          const recent_time = recent_date_time[1].substr(0, 5);
          if (now_date == recent_date) {
            //日付チェック
            if (item.regular_time <= recent_time) {
              // 正常
              status = 0;
            } else {
              // 異常
              status = 1;
            }
          } else {
            // 異常
            status = 1;
          }
        } else {
          //現在時刻が定期実行時刻前
          status = 2; // 判定なし
        }
      } else {
        //データなし
        status = 2; // 判定なし
      }
      return status;
    }
  }
};
</script>

<style></style>
