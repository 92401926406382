<template>
  <v-dialog v-model="dialog">
    <Groupinfo
      ref="group_info"
      :edit_flag="edit_flag"
      :edit_enable="edit_enable"
      :Group="group"
      @click="data => $emit('click', data)"
    />
  </v-dialog>
</template>

<script>
import Groupinfo from "./group_info.vue";
export default {
  name: "group_info_dialog",
  components: { Groupinfo },
  props: {
    edit_flag: {
      type: Boolean,
      default: true
    },
    edit_enable: {
      type: Boolean,
      default: false
    },
    group: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          address: ""
        };
      }
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    showDialog(bool) {
      this.$set(this, "dialog", bool);
    },
    init() {
      this.$refs["group_info"].init();
    }
  }
};
</script>

<style></style>
