import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSocialSharing from "vue-social-sharing";
import "@fortawesome/fontawesome-free/css/all.css";
import * as VueGoogleMaps from "vue2-google-maps";
import * as indexedDB from "./lib/IndexedDB_driver/indexedDB_driver.js";
import * as api from "./lib/api.js";
import * as fnc from "./lib/index.js";
Vue.config.productionTip = false;
indexedDB.initDB();
Vue.prototype.$db = indexedDB;
Vue.prototype.$api = api;
Vue.prototype.$fnc = fnc;

Vue.use(VueSocialSharing);

// google map
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAvsfzVZ0sQP4Q2UEG13jNoato0tz1zDag",
    libraries: "places",
    region: "JP",
    language: "ja",
    autobindAllEvents: false,
    installComponents: true
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
