<template>
  <v-tooltip bottom id="logout_btn">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-btn small fab color="primary" elevation="0" @click="$emit('logout')"
          ><v-icon color="white">
            mdi-logout
          </v-icon></v-btn
        >
      </span>
    </template>
    <span>Logout</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "logout_btn"
};
</script>

<style>
.add_btn {
  position: fixed;
  right: 50px;
  bottom: 50px;
  transition: 1s;
  opacity: 0.7;
}
</style>
