<template>
  <v-container id="devices_for_group" class="mb-3">
    <v-row>
      <v-col cols="12">
        <v-select v-model="show_type" :items="type_list" label="表示形式" />
      </v-col>
      <v-col cols="12">
        <span v-if="show_type == 'マップ'">
          <DeviceMap
            :dataset_table="datasets"
            @marker_click="data => show_dialog(data)"
          />
        </span>
        <span v-if="show_type == '表'">
          <DeviceTable
            :dataset_table="datasets"
            @edit="data => show_dialog(data)"
          />
        </span>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            カメラ情報
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="ID"
                  disabled
                  v-model="selected_device.id"
                />
                <v-text-field
                  label="Name"
                  disabled
                  v-model="selected_device.name"
                />
                <v-textarea
                  label="備考"
                  disabled
                  v-model="selected_device.description"
                />
              </v-col>
              <v-col cols="12">
                <DeviceData
                  :image_dialog_src="image_dialog_src"
                  :Devicedata="devicedata"
                  @image_show="im_show"
                  @download_image="download_image"
                />
              </v-col>
              <v-col cols="12">
                <GmapMap
                  id="mapFrame"
                  :center="edit_map_option.center"
                  :zoom="edit_map_option.zoom"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: true,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false
                  }"
                  style="width: 100%; height: 700px"
                  map-type-id="hybrid"
                >
                  <GmapMarker
                    @v-if="selected_device.position != undefined"
                    :position="selected_device.position"
                    :clickable="false"
                    :draggable="false"
                  >
                  </GmapMarker>
                </GmapMap>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Toast ref="toast" />
  </v-container>
</template>

<script>
import DeviceTable from "./device_table.vue";
import DeviceMap from "./device_map.vue";
import DeviceData from "./device_data.vue";
import Toast from "./toast.vue";
export default {
  name: "devices_for_group",
  components: {
    DeviceTable,
    DeviceMap,
    DeviceData,
    Toast
  },
  props: {
    group_id: {
      type: String,
      required: true
    }
  },
  computed: {
    datasets() {
      return Object.entries(this.devices).map(([key, value]) => ({
        id: key,
        ...value
      }));
    }
  },
  data() {
    return {
      show_type: "マップ",
      type_list: ["表", "マップ"],
      image_dialog_src: "",
      edit_map_option: {
        center: { lat: 36, lng: 138 },
        zoom: 15
      },
      dialog: false,
      devices: {},
      selected_device: {
        id: "",
        name: "",
        description: "",
        position: { lat: 36, lng: 138 }
      },

      devicedata: {}
    };
  },
  methods: {
    show_dialog(data) {
      console.log("@show dialog");
      this.$set(this, "edit_flag", true);

      this.$set(this, "edit_map_option", {
        center: data.position,
        zoom: 15
      });
      this.$set(this, "selected_device", data);
      this.set_device_data(data.id);
      this.$set(this, "dialog", true);
    },
    async im_show(data) {
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const image_src = await this.$api.get_group_device_blob(
        acceess_token,
        this.$route.params.group_id,
        this.selected_device.id,
        data.id
      );
      this.$set(this, "image_dialog_src", URL.createObjectURL(image_src));
    },
    async download_image(data) {
      console.log(data);
      let a = document.createElement("a");
      a.href = data.blob;
      a.download = `${data.id}.jpeg`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      this.$refs["toast"].showToast(
        "ダウンロード中です。しばらくお待ちください。"
      );
    },
    async set_device_data(device_id) {
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const devicedata = await this.$api.get_group_device_data(
        acceess_token,
        this.$route.params.group_id,
        device_id
      );
      this.$set(this, "devicedata", devicedata);
    }
  },
  async beforeMount() {
    const acceess_token = await this.$fnc.get_accesstoken(this.$db);
    const devices = await this.$api.get_group_device(
      acceess_token,
      this.group_id
    );
    this.$set(this, "devices", devices);
  }
};
</script>

<style></style>
