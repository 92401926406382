<template>
  <v-main id="sign_in">
    <v-container>
      <v-row>
        <v-col cols="12">
          <signInForm />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import signInForm from "../components/signin_form.vue";
export default {
  name: "sign_in",
  components: {
    signInForm
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style></style>
