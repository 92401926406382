<template>
  <v-container id="organization_table" class="pt-0 pb-0">
    <v-row>
      <v-col cols="12">
        <AddBtn v-if="admin_flag" @add="$emit('add')" />
        <DeleteBtn v-if="admin_flag" @remove="remove" />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-data-table
          v-model="selected"
          :headers="table.headers"
          :items="datasets"
          :item-key="table.item_key"
          :search="search"
          show-select
          class="elevation-1"
        >
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <EditBtn :plain="true" @edit="$emit('edit', item)" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddBtn from "./add_btn.vue";
import EditBtn from "./edit_btn.vue";
import DeleteBtn from "./delete_btn.vue";
export default {
  name: "organization_table",
  components: {
    AddBtn,
    EditBtn,
    DeleteBtn
  },
  props: {
    admin_flag: {
      type: Boolean,
      default: false
    },
    edit_enable: {
      type: Boolean,
      default: false
    },
    Organizations: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    datasets() {
      return Object.entries(this.Organizations).map(([key, value]) => ({
        id: key,
        ...value
      }));
    }
  },
  data() {
    return {
      table: {
        headers: [
          { text: "組織ID", value: "id" },
          { text: "Name", value: "name" },
          { text: "編集", value: "edit", align: "center" }
        ],
        item_key: "id"
      },
      selected: [],
      search: ""
    };
  },
  methods: {
    remove() {
      const selected = this.selected;
      this.$emit("remove", selected);
      this.$set(this, "selected", []);
    }
  }
};
</script>

<style></style>
