<template>
  <v-container id="mails_for_group">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="role == 0 ? table.headers.manage : table.headers.general"
          :sort-by="table.sort_by"
          :sort-desc="table.sort_desc"
          :items="mails"
          :item-key="table.item_key"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.device_id`]="{ item }">
            <span v-if="devices[item.device_id] != undefined">{{
              devices[item.device_id].name
            }}</span>
            <span v-else>No Device</span>
            （{{ item.device_id }}）
          </template>
          <template v-slot:[`item.users`]="{ item }">
            <v-chip v-for="user of item.users" :key="user">
              {{ user }}
            </v-chip>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <EditBtn :plain="true" @edit="row_click(item)" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> メール情報（{{ selected.id }}） </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="black--text">
          <v-container>
            <v-row>
              <v-col class="text-h5" cols="12">アラートID</v-col>
              <v-col cols="12">{{ selected.alert_id }}</v-col>
            </v-row>
            <v-row v-if="role == 0">
              <v-col class="text-h5" cols="12">送信先ユーザーID</v-col>
              <v-col cols="12">
                <v-chip v-for="user of selected.users" :key="user">
                  {{ user }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-h5" cols="12">メール本文</v-col>
              <v-col
                style="white-space:pre-wrap; word-wrap:break-word;"
                cols="12"
                v-if="selected.mail_text != undefined"
              >
                <p v-text="selected.mail_text" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import EditBtn from "./edit_btn.vue";
export default {
  name: "mails_for_group",
  components: { EditBtn },
  props: {
    group_id: {
      type: String,
      default: ""
    }
  },
  computed: {},
  data() {
    return {
      table: {
        sort_by: ["date"],
        sort_desc: [true],
        headers: {
          manage: [
            { text: "メールID", value: "id" },
            { text: "日付", value: "date" },
            { text: "カメラ", value: "device_id" },
            { text: "送信先ユーザーID", value: "users" },
            { text: "アラートID", value: "alert_id" },
            { text: "詳細", value: "edit" }
          ],
          general: [
            { text: "メールID", value: "id" },
            { text: "日付", value: "date" },
            { text: "カメラ", value: "device_id" },
            { text: "アラートID", value: "alert_id" },
            { text: "詳細", value: "edit" }
          ]
        },
        item_key: "id"
      },
      dialog: false,
      search: "",
      role: 2,
      selected: {},
      devices: {},
      mails: []
    };
  },
  methods: {
    row_click(data) {
      console.log(data);
      this.$set(this, "selected", data);
      this.$set(this, "dialog", true);
    }
  },
  async beforeMount() {
    const acceess_token = await this.$fnc.get_accesstoken(this.$db);
    const devices = await this.$api.get_group_device(
      acceess_token,
      this.group_id
    );
    const mails = await this.$api.get_mail_list(acceess_token, this.group_id);
    const group = await this.$api.get_group(acceess_token, this.group_id);
    let user = await this.$api.get_login_user(acceess_token);
    let user_ids = Object.keys(user);
    this.$set(
      this,
      "mails",
      Object.entries(mails).map(([key, value]) => ({
        id: key,
        ...value
      }))
    );
    this.$set(this, "devices", devices);
    this.$set(this, "role", group[this.group_id].role[user_ids[0]]);
  }
};
</script>

<style></style>
