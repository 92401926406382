<template>
  <v-data-table
    :headers="[
      { text: 'カメラID', value: 'id' },
      { text: 'カメラ名', value: 'name' },
      { text: '設置日', value: 'date', align: 'center' },
      { text: '更新日', value: 'recent', align: 'center' },
      { text: 'ステータス', value: 'status', align: 'center' },
      { text: '詳細', value: 'edit', align: 'center' }
    ]"
    :items="
      dataset_table.map(value => ({
        id: value.id,
        name: value.name,
        date: value.date,
        regular_time: value.regular_time,
        position: value.position,
        endpoint_email: value.endpoint_email != undefined,
        recent:
          Object.keys(value.recent).length != 0
            ? Object.entries(value.recent)[0][1].date
            : 'データなし'
      }))
    "
    :item-key="table.item_key"
    :search="search"
    class="elevation-1"
    id="device_table"
  >
    <template v-slot:top>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Search"
              append-icon="mdi-magnify"
              v-model="search"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-icon :color="icon_color(item)">{{ icon(item) }}</v-icon>
    </template>
    <template v-slot:[`item.edit`]="{ item }">
      <EditBtn :plain="true" @edit="$emit('edit', item)" />
    </template>
  </v-data-table>
</template>

<script>
import EditBtn from "./edit_btn.vue";
export default {
  name: "device_table",
  components: { EditBtn },
  props: {
    dataset_table: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      table: {
        headers: [
          { text: "カメラID", value: "id" },
          { text: "カメラ名", value: "name" },
          { text: "設置日", value: "date", align: "center" },
          { text: "更新日", value: "recent", align: "center" },
          { text: "ステータス", value: "status", align: "center" },
          { text: "詳細", value: "edit", align: "center" }
        ],
        item_key: "id"
      },
      search: "",
      now: new Date()
    };
  },
  methods: {
    icon_color(item) {
      const status = this.get_status(item);
      let icon_color = "gray";
      if (status == 0) {
        icon_color = "blue";
      } else if (status == 1) {
        icon_color = "red";
      } else if (status == 2) {
        icon_color = "gray";
      }
      return icon_color;
    },
    icon(item) {
      const status = this.get_status(item);
      let icon = "";
      if (status == 0) {
        icon = "mdi-check";
      } else if (status == 1) {
        icon = "mdi-close";
      } else if (status == 2) {
        icon = "mdi-minus";
      }
      return icon;
    },
    get_status(item) {
      const now_time =
        ("0" + this.now.getHours()).slice(-2) +
        ":" +
        ("0" + this.now.getMinutes()).slice(-2);
      const now_date = this.now.toISOString().substr(0, 10);
      let status = 2;

      if (item.recent != "データなし") {
        //データ有
        if (now_time >= item.regular_time) {
          //現在時刻が定期実行時刻後
          let recent_date_time = item.recent.split(" ");
          const recent_date = recent_date_time[0].substr(0, 10);
          const recent_time = recent_date_time[1].substr(0, 5);
          if (now_date == recent_date) {
            //日付チェック
            if (item.regular_time <= recent_time) {
              // 正常
              status = 0;
            } else {
              // 異常
              status = 1;
            }
          } else {
            // 異常
            status = 1;
          }
        } else {
          //現在時刻が定期実行時刻前
          status = 2; // 判定なし
        }
      } else {
        //データなし
        status = 2; // 判定なし
      }
      return status;
    }
  }
};
</script>

<style></style>
