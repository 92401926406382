<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="delete_btn"
        class="mx-2"
        small
        fab
        color="error"
        :elevation="elevation"
        v-bind="attrs"
        v-on="on"
        ><v-icon>
          mdi-delete
        </v-icon></v-btn
      >
    </template>
    <v-card>
      <v-card-title>削除確認</v-card-title>
      <v-card-text>本当によろしいですか？</v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="error" @click="click" text>OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "delete_btn",
  props: {
    elevation: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    click() {
      this.$emit("remove");
      this.dialog = false;
    }
  }
};
</script>

<style>
.add_btn {
  position: fixed;
  right: 50px;
  bottom: 50px;
  transition: 1s;
  opacity: 0.7;
}
</style>
