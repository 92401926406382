<template>
  <v-container id="users_for_manage">
    <v-row>
      <v-col cols="12">
        <AddBtn @add="add_dialog(true)" />
        <DeleteBtn @remove="remove" />
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="table.headers"
          :items="datasets"
          :item-key="table.item_key"
          :search="search"
          show-select
          class="elevation-1"
          @click:row="data => row_click(data)"
        >
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <span v-if="item.role == 0">管理者</span>
            <span v-if="item.role == 1">一般</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <UserInfo ref="add_form" :edit_flag="false" @click="add" />
    <UserInfo ref="edit_form" :edit_flag="true" @click="edit" />
    <Toast ref="toast" />
  </v-container>
</template>

<script>
import UserInfo from "./user_form.vue";
import AddBtn from "./add_btn.vue";
import DeleteBtn from "./delete_btn.vue";
import Toast from "./toast.vue";
export default {
  name: "users_for_manage",
  components: {
    UserInfo,
    AddBtn,
    DeleteBtn,
    Toast
  },
  computed: {
    datasets() {
      return Object.entries(this.users).map(([key, value]) => ({
        id: key,
        ...value
      }));
    }
  },
  data() {
    return {
      table: {
        headers: [
          { text: "ユーザーID", value: "id" },
          { text: "Name", value: "name" },
          { text: "Password", value: "password" },
          { text: "Email", value: "email" },
          { text: "役割", value: "role", align: "center" }
        ],
        item_key: "id"
      },
      selected: [],
      search: "",
      users: {}
    };
  },
  methods: {
    add_dialog(bool) {
      this.$set(this.$refs["add_form"], "dialog", bool);
    },
    edit_dialog(bool) {
      this.$set(this.$refs["edit_form"], "dialog", bool);
    },
    row_click(data) {
      let tmp = {};
      Object.assign(tmp, data);
      this.$set(this.$refs["edit_form"], "user", tmp);
      this.edit_dialog(true);
    },
    async set_data(data) {
      this.$set(this.users, data.id, {
        name: data.name,
        password: data.password,
        email: data.email,
        role: data.role
      });
    },
    async remove() {
      const selected = this.selected;
      if (selected.length == 0) {
        this.$refs["toast"].showToast("一つ以上選択してください");
      } else {
        for (let data of selected) {
          console.log(data);
          const acceess_token = await this.$fnc.get_accesstoken(this.$db);
          const exe = async () => {
            const result = await this.$api.delete_user(acceess_token, data.id);
            if (result) {
              this.$delete(this.users, data.id);
            }
          };
          exe();
        }
        this.$refs["toast"].showToast("削除しました");
        this.$set(this, "selected", []);
      }
    },
    async add(data) {
      console.log("@add", data);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.add_user(
        acceess_token,
        data.id,
        data.name,
        data.password,
        data.email,
        data.role
      );
      if (result) {
        this.$refs["toast"].showToast("追加しました");
        this.set_data(data);
        this.$refs["add_form"].init();
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
      this.add_dialog(false);
    },
    async edit(data) {
      console.log("@edist", data);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.set_user(
        acceess_token,
        data.id,
        data.name,
        data.password,
        data.email,
        data.role
      );

      if (result) {
        this.$refs["toast"].showToast("変更しました");
        this.set_data(data);
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
      this.edit_dialog(false);
    }
  },
  async beforeMount() {
    const acceess_token = await this.$fnc.get_accesstoken(this.$db);
    const users = await this.$api.get_user_list(acceess_token);
    this.$set(this, "users", users);
  }
};
</script>

<style></style>
