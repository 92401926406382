<template>
  <v-dialog v-model="dialog">
    <v-card id="user_form">
      <v-card-title>ユーザ詳細</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="ID"
                :disabled="edit_flag"
                v-model="user.id"
              />
              <v-text-field label="Name" v-model="user.name" />
              <v-text-field label="Password" v-model="user.password" />
              <v-text-field label="Email" v-model="user.email" />
              <v-radio-group label="役割" v-model.number="user.role" :max="1">
                <v-radio label="管理者" :value="0"></v-radio>
                <v-radio label="一般" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="secondary" @click="$emit('click', user)">
          <span v-if="edit_flag">変更</span>
          <span v-else>追加</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "user_form",
  props: {
    edit_flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialog: false,
      user: {
        id: "",
        name: "",
        password: "",
        email: "",
        role: 0
      }
    };
  },
  methods: {
    init() {
      this.$set(this, "user", {
        id: "",
        name: "",
        password: "",
        email: "",
        role: 0
      });
    }
  }
};
</script>

<style></style>
