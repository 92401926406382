<template>
  <v-container id="group_alert">
    <v-row>
      <v-col cols="12">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <AddBtn v-if="admin_flag" @add="show_dialog({}, 'add')" />
            </span>
          </template>
          <span>追加</span>
        </v-tooltip>
        <v-tooltip bottom v-if="alert_selected.length !== 0">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                class="mx-2"
                small
                fab
                color="secondary"
                outlined
                elevation="4"
                @click="show_copy_dialog"
              >
                <v-icon color="black">mdi-content-copy</v-icon>
              </v-btn>
            </span>
          </template>
          <span>コピー</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="alert_selected"
          :headers="[
            { text: 'アラートID', value: 'id' },
            { text: 'アラート名', value: 'name' },
            { text: 'デバイス名', value: 'device_id' },
            { text: '詳細', value: 'edit', align: 'center' }
          ]"
          :items="
            Object.entries(alerts).map(([key, value]) => ({
              id: key,
              ...value
            }))
          "
          :item-key="'id'"
          :search="search"
          show-select
          single-select
          class="elevation-1"
          id="device_table"
        >
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-magnify"
                    v-model="search"
                  />
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.edit`]="{ item }">
            <EditBtn :plain="true" @edit="show_dialog(item, 'edit')" />
          </template>
          <template v-slot:[`item.device_id`]="{ item }">
            {{ device_name(item) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Toast ref="toast" />
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span v-if="edit_flag">変更</span>
            <span v-else>追加</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="ID"
                  disabled
                  v-model="selected.id"
                  v-if="edit_flag"
                />
                <v-text-field
                  label="Name"
                  :disabled="!edit_enable"
                  v-model="selected.name"
                />
                <v-textarea
                  label="備考"
                  :disabled="!edit_enable"
                  v-model="selected.description"
                />
                <v-data-table
                  v-model="device_selected"
                  :headers="[
                    { text: 'カメラID', value: 'id' },
                    { text: 'カメラ名', value: 'name' }
                  ]"
                  :items="
                    Object.entries(devices).map(([key, value]) => ({
                      id: key,
                      ...value
                    }))
                  "
                  :item-key="'id'"
                  :search="device_search"
                  :show-select="edit_enable"
                  single-select
                  class="elevation-1"
                  id="device_table"
                >
                  <template v-slot:top>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Search"
                            append-icon="mdi-magnify"
                            v-model="device_search"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </v-data-table>
                <v-card class="mt-4" elevation="1">
                  <v-card-title>宛先ユーザー</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row v-for="(_, key) of selected.users" :key="key">
                        <v-col cols="4">
                          <v-text-field
                            label="ユーザーID"
                            :role="[rules.required]"
                            :disabled="!edit_enable"
                            v-model="selected.users[key].user_id"
                          >
                            <template v-slot:prepend>
                              <v-checkbox
                                :disabled="!edit_enable"
                                v-model="selected.users[key].flag"
                              ></v-checkbox>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="8">
                          <v-input>
                            <v-menu
                              :disabled="!edit_enable"
                              v-model="selected.users[key].start_time_menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="selected.users[key].start"
                                  label="開始時刻"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="selected.users[key].start_time_menu"
                                v-model="selected.users[key].start"
                                :max="selected.users[key].end"
                                format="24hr"
                                full-width
                                @change="value => start_time_set(key, value)"
                              ></v-time-picker>
                            </v-menu>
                            <v-menu
                              :disabled="!edit_enable"
                              v-model="selected.users[key].end_time_menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="selected.users[key].end"
                                  label="終了時刻"
                                  prepend-icon="mdi-clock-time-four-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                v-if="selected.users[key].end_time_menu"
                                v-model="selected.users[key].end"
                                :min="selected.users[key].start"
                                format="24hr"
                                full-width
                                @change="value => end_time_set(key, value)"
                              ></v-time-picker>
                            </v-menu>
                            <v-checkbox
                              label="すべて送信"
                              v-model="selected.users[key].all_send_flag"
                            >
                            </v-checkbox>
                            <v-select
                              v-model="selected.users[key].alert_target"
                              :items="alert_target_list"
                              item-text="display_name"
                              item-value="value"
                              label="警報対象"
                              class="ml-5"
                            />
                            <template v-slot:append>
                              <v-btn
                                v-if="edit_enable"
                                small
                                fab
                                icon
                                elevation="0"
                                @click="remove_alert_user(key)"
                              >
                                <v-icon>
                                  mdi-close
                                </v-icon>
                              </v-btn>
                            </template>
                          </v-input>
                        </v-col>
                        <v-col cols="12">
                          <v-divider />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="justify-end" v-if="edit_enable">
                    <v-btn @click="add_alert_user" color="secondary"
                      >ユーザ追加</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end" v-if="edit_enable">
          <v-dialog
            v-model="delete_dialog"
            width="500"
            v-if="edit_flag & admin_flag"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-2" color="error" v-bind="attrs" v-on="on">
                削除
              </v-btn>
            </template>
            <v-card>
              <v-card-title>削除確認</v-card-title>
              <v-card-text>本当によろしいですか？</v-card-text>
              <v-card-actions class="justify-end">
                <v-btn color="error" @click="remove" text>OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn v-if="edit_flag" color="secondary" @click="edit">
            変更
          </v-btn>
          <v-btn v-else color="secondary" @click="add">
            追加
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Toast from "./toast.vue";
import AddBtn from "./add_btn.vue";
import EditBtn from "./edit_btn.vue";
export default {
  name: "group_alert",
  components: {
    Toast,
    AddBtn,
    EditBtn
  },
  computed: {},
  props: {
    group_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      alert_target_list: [
        { display_name: "すべて（獣、人・車両）", value: "all" },
        { display_name: "獣", value: "vermin" },
        { display_name: "人・車両", value: "human_or_vehicle" }
      ],
      alert_selected: [],
      rules: {
        required: value => !!value || "Required."
      },
      search: "",
      device_search: "",
      device_selected: [],
      dialog: false,
      delete_dialog: false,
      admin_flag: false,
      edit_flag: false,
      edit_enable: false,
      alerts: {},
      devices: {},
      selected: {
        id: "",
        name: "",
        description: "",
        device_id: "",
        users: [
          {
            user_id: "",
            start: "09:00",
            end: "17:00",
            flag: true,
            all_send_flag: false,
            alert_target: "all",
            start_time_menu: false,
            end_time_menu: false
          }
        ]
      }
    };
  },
  methods: {
    device_name(item) {
      if (this.devices[item.device_id] !== undefined) {
        return `${this.devices[item.device_id].name}（${item.device_id}）`;
      } else {
        return "該当デバイスなし";
      }
    },
    add_menu(user) {
      let tmp = {};
      Object.assign(tmp, user);
      tmp.start_time_menu = false;
      tmp.end_time_menu = false;
      return tmp;
    },
    delete_menu(users) {
      let tmp = [];
      Object.assign(tmp, users);
      for (let i in tmp) {
        delete tmp[i].start_time_menu;
        delete tmp[i].end_time_menu;
      }
      return tmp;
    },
    initial_user() {
      return {
        user_id: "",
        start: "09:00",
        end: "17:00",
        flag: true,
        alert_target: this.alert_target_list[0].value,
        start_time_menu: false,
        end_time_menu: false
      };
    },
    show_copy_dialog() {
      if (this.alert_selected.length == 0) {
        this.$refs["toast"].showToast("アラートを1つ選択してください");
        return;
      }
      this.$set(this, "edit_flag", false);
      let data = {};
      Object.assign(data, this.alert_selected[0]);
      data = this.add_menu(data);
      data.id = "";
      data.name = `${data.name} copy`;
      this.device_selected.splice(0, 1, {
        id: data.device_id,
        ...this.devices[data.device_id]
      });
      this.$set(this, "selected", data);
      this.$set(this, "dialog", true);
    },
    show_dialog(data, form_type) {
      if (form_type == "add") {
        this.$set(this, "edit_flag", false);
        this.$set(this, "selected", {
          id: "",
          name: "",
          description: "",
          device_id: "",
          users: [this.initial_user()]
        });
      } else if (form_type == "edit") {
        data = this.add_menu(data);
        this.device_selected.splice(0, 1, {
          id: data.device_id,
          ...this.devices[data.device_id]
        });
        this.$set(this, "edit_flag", true);
        this.$set(this, "selected", data);
      }
      this.$set(this, "dialog", true);
    },
    start_time_set(key, value) {
      console.log(key, value);
      this.$set(this.selected.users[key], "start", value);
      console.log(this.selected.users[key]);
      this.$set(this.selected.users[key], "start_time_menu", false);
    },
    end_time_set(key, value) {
      console.log(key, value);
      this.$set(this.selected.users[key], "end", value);
      console.log(this.selected.users[key]);
      this.$set(this.selected.users[key], "end_time_menu", false);
    },
    add_alert_user() {
      this.selected.users.push(this.initial_user());
    },
    remove_alert_user(key) {
      this.selected.users.splice(key, 1);
    },
    async add() {
      console.log(this.selected);
      if (this.device_selected[0].id == "") {
        this.$refs["toast"].showToast("デバイスを選択してください");
        return;
      }
      const device_id = this.device_selected[0].id;
      const result = await this.$api.add_alert(
        this.$store.state.token.access_token,
        this.group_id,
        this.selected.name,
        this.selected.description,
        device_id,
        this.delete_menu(this.selected.users)
      );
      console.log(result);
      if (result.alert_id != undefined) {
        this.selected["id"] = result["alert_id"];
        this.$set(this.alerts, result["alert_id"], {
          name: this.selected.name,
          description: this.selected.description,
          device_id: device_id,
          users: this.selected.users
        });
        this.$set(this, "dialog", false);
        this.$refs["toast"].showToast("追加しました");
      } else {
        this.$refs["toast"].showToast("追加に失敗しました");
      }
    },
    async edit() {
      console.log("@edit", this.selected);
      const device_id = this.device_selected[0].id;
      const result = await this.$api.set_alert(
        this.$store.state.token.access_token,
        this.group_id,
        this.selected.id,
        this.selected.name,
        this.selected.description,
        device_id,
        this.delete_menu(this.selected.users)
      );

      if (result) {
        this.$refs["toast"].showToast("変更しました");
        this.$set(this.alerts, this.selected.id, {
          name: this.selected.name,
          description: this.selected.description,
          device_id: device_id,
          users: this.selected.users
        });
        this.$set(this, "dialog", false);
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
    },
    async remove() {
      try {
        this.$refs["toast"].showToast("削除中");
        const result = await this.$api.delete_alert(
          this.$store.state.token.access_token,
          this.group_id,
          this.selected.id
        );
        if (result) {
          this.$delete(this.alerts, this.selected.id);
          this.$set(this, "delete_dialog", false);
          this.$set(this, "dialog", false);
          this.$refs["toast"].showToast("削除しました");
        } else {
          this.$refs["toast"].showToast("削除に失敗しました");
        }
      } catch {
        this.$refs["toast"].showToast("エラーが発生しました");
      }
    },
    role_check() {
      const group = this.$store.state.group;
      const role = this.$store.state.user.role;
      const user_id = this.$store.state.token.user_id;

      try {
        if (role == 0) {
          this.$set(this, "admin_flag", true);
          this.$set(this, "edit_enable", true);
        } else {
          if (group.role[user_id] == 0) {
            this.$set(this, "admin_flag", true);
            this.$set(this, "edit_enable", true);
          } else if (group.role[user_id] == 1) {
            this.$set(this, "admin_flag", false);
            this.$set(this, "edit_enable", true);
          } else if (group.role[user_id] == 2) {
            this.$set(this, "admin_flag", false);
            this.$set(this, "edit_enable", false);
          }
        }
      } catch {
        this.$router.push({ name: "SignIn" });
      }
    }
  },
  async beforeMount() {
    setTimeout(async () => {
      this.role_check();

      const get_devices = async () => {
        const devices = await this.$api.get_group_device(
          this.$store.state.token.access_token,
          this.group_id
        );
        this.$set(this, "devices", devices);
      };

      const get_alerts = async () => {
        let alerts = await this.$api.get_alert_list(
          this.$store.state.token.access_token,
          this.group_id
        );
        this.$set(this, "alerts", alerts);
      };
      get_devices();
      get_alerts();
    }, 500);
  }
};
</script>

<style></style>
