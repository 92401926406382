var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"group_alert"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.admin_flag)?_c('AddBtn',{on:{"add":function($event){return _vm.show_dialog({}, 'add')}}}):_vm._e()],1)]}}])},[_c('span',[_vm._v("追加")])]),(_vm.alert_selected.length !== 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","fab":"","color":"secondary","outlined":"","elevation":"4"},on:{"click":_vm.show_copy_dialog}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-content-copy")])],1)],1)]}}],null,false,1893979441)},[_c('span',[_vm._v("コピー")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
          { text: 'アラートID', value: 'id' },
          { text: 'アラート名', value: 'name' },
          { text: 'デバイス名', value: 'device_id' },
          { text: '詳細', value: 'edit', align: 'center' }
        ],"items":Object.entries(_vm.alerts).map(function (ref) {
                var key = ref[0];
                var value = ref[1];

                return (Object.assign({}, {id: key},
            value));
}),"item-key":'id',"search":_vm.search,"show-select":"","single-select":"","id":"device_table"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1)]},proxy:true},{key:"item.edit",fn:function(ref){
          var item = ref.item;
return [_c('EditBtn',{attrs:{"plain":true},on:{"edit":function($event){return _vm.show_dialog(item, 'edit')}}})]}},{key:"item.device_id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.device_name(item))+" ")]}}],null,true),model:{value:(_vm.alert_selected),callback:function ($$v) {_vm.alert_selected=$$v},expression:"alert_selected"}})],1)],1),_c('Toast',{ref:"toast"}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[(_vm.edit_flag)?_c('span',[_vm._v("変更")]):_c('span',[_vm._v("追加")])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.edit_flag)?_c('v-text-field',{attrs:{"label":"ID","disabled":""},model:{value:(_vm.selected.id),callback:function ($$v) {_vm.$set(_vm.selected, "id", $$v)},expression:"selected.id"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"Name","disabled":!_vm.edit_enable},model:{value:(_vm.selected.name),callback:function ($$v) {_vm.$set(_vm.selected, "name", $$v)},expression:"selected.name"}}),_c('v-textarea',{attrs:{"label":"備考","disabled":!_vm.edit_enable},model:{value:(_vm.selected.description),callback:function ($$v) {_vm.$set(_vm.selected, "description", $$v)},expression:"selected.description"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                  { text: 'カメラID', value: 'id' },
                  { text: 'カメラ名', value: 'name' }
                ],"items":Object.entries(_vm.devices).map(function (ref) {
                        var key = ref[0];
                        var value = ref[1];

                        return (Object.assign({}, {id: key},
                    value));
}),"item-key":'id',"search":_vm.device_search,"show-select":_vm.edit_enable,"single-select":"","id":"device_table"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.device_search),callback:function ($$v) {_vm.device_search=$$v},expression:"device_search"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.device_selected),callback:function ($$v) {_vm.device_selected=$$v},expression:"device_selected"}}),_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v("宛先ユーザー")]),_c('v-card-text',[_c('v-container',_vm._l((_vm.selected.users),function(_,key){return _c('v-row',{key:key},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"ユーザーID","role":[_vm.rules.required],"disabled":!_vm.edit_enable},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-checkbox',{attrs:{"disabled":!_vm.edit_enable},model:{value:(_vm.selected.users[key].flag),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "flag", $$v)},expression:"selected.users[key].flag"}})]},proxy:true}],null,true),model:{value:(_vm.selected.users[key].user_id),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "user_id", $$v)},expression:"selected.users[key].user_id"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-input',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.edit_enable)?_c('v-btn',{attrs:{"small":"","fab":"","icon":"","elevation":"0"},on:{"click":function($event){return _vm.remove_alert_user(key)}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e()]},proxy:true}],null,true)},[_c('v-menu',{attrs:{"disabled":!_vm.edit_enable,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"開始時刻","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.selected.users[key].start),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "start", $$v)},expression:"selected.users[key].start"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.selected.users[key].start_time_menu),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "start_time_menu", $$v)},expression:"selected.users[key].start_time_menu"}},[(_vm.selected.users[key].start_time_menu)?_c('v-time-picker',{attrs:{"max":_vm.selected.users[key].end,"format":"24hr","full-width":""},on:{"change":function (value) { return _vm.start_time_set(key, value); }},model:{value:(_vm.selected.users[key].start),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "start", $$v)},expression:"selected.users[key].start"}}):_vm._e()],1),_c('v-menu',{attrs:{"disabled":!_vm.edit_enable,"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"終了時刻","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.selected.users[key].end),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "end", $$v)},expression:"selected.users[key].end"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.selected.users[key].end_time_menu),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "end_time_menu", $$v)},expression:"selected.users[key].end_time_menu"}},[(_vm.selected.users[key].end_time_menu)?_c('v-time-picker',{attrs:{"min":_vm.selected.users[key].start,"format":"24hr","full-width":""},on:{"change":function (value) { return _vm.end_time_set(key, value); }},model:{value:(_vm.selected.users[key].end),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "end", $$v)},expression:"selected.users[key].end"}}):_vm._e()],1),_c('v-checkbox',{attrs:{"label":"すべて送信"},model:{value:(_vm.selected.users[key].all_send_flag),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "all_send_flag", $$v)},expression:"selected.users[key].all_send_flag"}}),_c('v-select',{staticClass:"ml-5",attrs:{"items":_vm.alert_target_list,"item-text":"display_name","item-value":"value","label":"警報対象"},model:{value:(_vm.selected.users[key].alert_target),callback:function ($$v) {_vm.$set(_vm.selected.users[key], "alert_target", $$v)},expression:"selected.users[key].alert_target"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1)],1)}),1)],1),(_vm.edit_enable)?_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.add_alert_user}},[_vm._v("ユーザ追加")])],1):_vm._e()],1)],1)],1)],1)],1),(_vm.edit_enable)?_c('v-card-actions',{staticClass:"justify-end"},[(_vm.edit_flag & _vm.admin_flag)?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v(" 削除 ")])]}}],null,false,2341662712),model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("削除確認")]),_c('v-card-text',[_vm._v("本当によろしいですか？")]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.remove}},[_vm._v("OK")])],1)],1)],1):_vm._e(),(_vm.edit_flag)?_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.edit}},[_vm._v(" 変更 ")]):_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.add}},[_vm._v(" 追加 ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }