<template>
  <div id="group_users">
    <HeaderFrame :nav_item="header_items" />
    <v-main>
      <UserFrame />
    </v-main>
  </div>
</template>

<script>
import HeaderFrame from "../components/header.vue";
import UserFrame from "../components/group_users.vue";
import constants from "../common/constants";
export default {
  name: "group_users",
  components: {
    HeaderFrame,
    UserFrame
  },
  computed: {
    header_items() {
      return constants.group_header(this.$route.params.group_id);
    }
  },
  data() {
    return {};
  },
  async beforeMount() {}
};
</script>

<style></style>
