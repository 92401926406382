<template>
  <v-dialog v-model="dialog">
    <v-card id="user_role_form">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="ID"
                :disabled="edit_flag"
                v-model="user.id"
              />
              <v-radio-group label="役割" v-model.number="user.role" :max="2">
                <v-radio label="管理者" :value="0"></v-radio>
                <v-radio label="編集者" :value="1"></v-radio>
                <v-radio label="閲覧者" :value="2"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          color="secondary"
          @click="$emit('click', user)"
          :disabled="!edit_enable"
        >
          <span v-if="edit_flag">変更</span>
          <span v-else>追加</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "user_role_form",
  props: {
    edit_enable: {
      type: Boolean,
      default: false
    },
    edit_flag: {
      type: Boolean,
      default: true
    },
    user_id: {
      type: String,
      default: ""
    },
    Role: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      dialog: false,
      user: {
        id: this.user_id,
        role: this.Role
      }
    };
  },
  methods: {
    init() {
      this.$set(this, "user", {
        id: "",
        role: 1
      });
    }
  }
};
</script>

<style></style>
