<template>
  <v-card>
    <v-card-title>{{ card_title }}</v-card-title>
    <v-card-actions>
      <v-btn text color="teal accent-4" @click="$emit('click')">
        Enter
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "card",
  props: {
    card_title: {
      type: String
    }
  }
};
</script>

<style></style>
