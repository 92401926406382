<template>
  <v-card id="organization_form">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="組織ID"
              disabled
              v-if="edit_flag"
              v-model="organization.id"
            />
            <v-text-field label="名前" v-model="organization.name" />
            <v-textarea label="説明" v-model="organization.description" />
            <!-- グループ選択 -->
            <v-data-table
              v-model="group_selected"
              v-if="edit_flag"
              :headers="[
                { text: 'グループID', value: 'id' },
                { text: 'Name', value: 'name' },
                { text: '', value: 'move' }
              ]"
              :items="groupdatasets"
              :item-key="'id'"
              :search="group_search"
              show-select
              class="elevation-1 mb-1"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row v-if="edit_flag">
                    <v-col cols="2">
                      <AddBtn @add="$emit('add_group')" />
                      <DeleteBtn @remove="remove_group" />
                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                        label="Search"
                        append-icon="mdi-magnify"
                        v-model="group_search"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-slot:[`item.move`]="{ item }">
                <v-btn plain @click="$emit('move', item.id)"
                  ><v-icon>mdi-arrow-right</v-icon></v-btn
                >
              </template>
            </v-data-table>
            <!-- ユーザ選択 -->
            <v-data-table
              v-model="user_selected"
              :headers="[
                { text: 'ユーザーID', value: 'id' },
                { text: 'Name', value: 'name' }
              ]"
              :items="userdatasets"
              :item-key="'id'"
              :search="user_search"
              show-select
              class="elevation-1 mb-1"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row v-if="edit_flag">
                    <v-col cols="2">
                      <AddBtn @add="$emit('add_user')" />
                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                        label="Search"
                        append-icon="mdi-magnify"
                        v-model="user_search"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
            <!-- デバイス選択 -->
            <v-data-table
              v-model="device_selected"
              :headers="[
                { text: 'カメラID', value: 'id' },
                { text: 'Name', value: 'name' }
              ]"
              :items="devicedatasets"
              :item-key="'id'"
              :search="device_search"
              show-select
              class="elevation-1"
            >
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Search"
                        append-icon="mdi-magnify"
                        v-model="device_search"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="secondary" @click="$emit('cancel')">キャンセル</v-btn>
      <v-btn color="secondary" @click="click" :disabled="!edit_enable">
        <span v-if="edit_flag">変更</span>
        <span v-else>追加</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import AddBtn from "./add_btn.vue";
import DeleteBtn from "./delete_btn.vue";
export default {
  name: "organization_form",
  components: { AddBtn, DeleteBtn },
  computed: {
    groupdatasets() {
      return Object.entries(this.Organization.groups).map(([key, value]) => ({
        id: key,
        ...value
      }));
    },
    userdatasets() {
      return Object.entries(this.Users).map(([key, value]) => ({
        id: key,
        ...value
      }));
    },
    devicedatasets() {
      return Object.entries(this.Devices).map(([key, value]) => ({
        id: key,
        ...value
      }));
    }
  },
  props: {
    edit_flag: {
      type: Boolean,
      default: true
    },
    edit_enable: {
      type: Boolean,
      default: false
    },
    Organization: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          description: "",
          groups: {},
          users: [],
          devices: []
        };
      }
    },
    Users: {
      type: Object,
      default() {
        return {};
      }
    },
    Devices: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    Organization(v) {
      this.organization = v;
    }
  },
  data() {
    return {
      organization: this.Organization,
      group_selected: [],
      user_selected: this.set_user_selected(this.Organization),
      device_selected: this.set_device_selected(this.Organization),
      group_search: "",
      user_search: "",
      device_search: ""
    };
  },
  methods: {
    set_user_selected(organization) {
      if (organization.users.length != 0) {
        let selected = [];
        for (let user_id of organization.users) {
          selected.push({
            id: user_id,
            ...this.Users[user_id]
          });
        }
        return selected;
      } else {
        return [];
      }
    },
    set_device_selected(organization) {
      if (organization.devices.length != 0) {
        let selected = [];
        for (let device_id of organization.devices) {
          selected.push({
            id: device_id,
            ...this.Devices[device_id]
          });
        }
        return selected;
      } else {
        return [];
      }
    },
    click() {
      const user_ids = this.user_selected.map(value => value.id);
      const device_ids = this.device_selected.map(value => value.id);
      this.$emit("click", {
        id: this.organization.id,
        name: this.organization.name,
        description: this.organization.description,
        users: user_ids,
        devices: device_ids
      });
    },
    init() {
      this.$set(this, "organization", {
        id: "",
        name: "",
        description: "",
        groups: {},
        users: [],
        devices: []
      });
    },
    remove_group() {
      this.$emit("remove_group", this.group_selected);
      this.$set(this, "group_selected", []);
    }
  }
};
</script>

<style></style>
