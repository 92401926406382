import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import localForage from "localforage";

Vue.use(Vuex);
const vuexLocal = new VuexPersist({
  storage: localForage,
  asyncStorage: true
});

export default new Vuex.Store({
  state: {
    token: {
      user_id: "",
      access_token: "",
      refresh_token: ""
    },
    user: {},
    devices: {},
    group: {}
  },
  getters: {
    get_access_token(state) {
      return state.token.access_token;
    }
  },
  mutations: {
    set_token(state, n) {
      state.token = n;
    },
    set_user(state, n) {
      state.user = n;
    },
    set_devices(state, n) {
      state.devices = n;
    },
    set_group(state, n) {
      state.group = n;
    }
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin]
});
