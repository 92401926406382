<template>
  <div id="manage_device_data">
    <HeaderFrame :nav_item="header_items" />
    <v-main>
      <ManageGroups />
    </v-main>
  </div>
</template>

<script>
import HeaderFrame from "../components/header.vue";
import ManageGroups from "../components/manage_groups.vue";
import constants from "../common/constants";
export default {
  name: "manage_device_data",
  components: {
    HeaderFrame,
    ManageGroups
  },
  computed: {
    header_items() {
      return this.mangage_header[0];
    }
  },
  data() {
    return {
      mangage_header: constants.mangage_header
    };
  }
};
</script>

<style></style>
