var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"id":"organization_form"}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.edit_flag)?_c('v-text-field',{attrs:{"label":"組織ID","disabled":""},model:{value:(_vm.organization.id),callback:function ($$v) {_vm.$set(_vm.organization, "id", $$v)},expression:"organization.id"}}):_vm._e(),_c('v-text-field',{attrs:{"label":"名前"},model:{value:(_vm.organization.name),callback:function ($$v) {_vm.$set(_vm.organization, "name", $$v)},expression:"organization.name"}}),_c('v-textarea',{attrs:{"label":"説明"},model:{value:(_vm.organization.description),callback:function ($$v) {_vm.$set(_vm.organization, "description", $$v)},expression:"organization.description"}}),(_vm.edit_flag)?_c('v-data-table',{staticClass:"elevation-1 mb-1",attrs:{"headers":[
              { text: 'グループID', value: 'id' },
              { text: 'Name', value: 'name' },
              { text: '', value: 'move' }
            ],"items":_vm.groupdatasets,"item-key":'id',"search":_vm.group_search,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[(_vm.edit_flag)?_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('AddBtn',{on:{"add":function($event){return _vm.$emit('add_group')}}}),_c('DeleteBtn',{on:{"remove":_vm.remove_group}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.group_search),callback:function ($$v) {_vm.group_search=$$v},expression:"group_search"}})],1)],1):_vm._e()],1)]},proxy:true},{key:"item.move",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.$emit('move', item.id)}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}],null,true),model:{value:(_vm.group_selected),callback:function ($$v) {_vm.group_selected=$$v},expression:"group_selected"}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 mb-1",attrs:{"headers":[
              { text: 'ユーザーID', value: 'id' },
              { text: 'Name', value: 'name' }
            ],"items":_vm.userdatasets,"item-key":'id',"search":_vm.user_search,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[(_vm.edit_flag)?_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('AddBtn',{on:{"add":function($event){return _vm.$emit('add_user')}}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.user_search),callback:function ($$v) {_vm.user_search=$$v},expression:"user_search"}})],1)],1):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.user_selected),callback:function ($$v) {_vm.user_selected=$$v},expression:"user_selected"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
              { text: 'カメラID', value: 'id' },
              { text: 'Name', value: 'name' }
            ],"items":_vm.devicedatasets,"item-key":'id',"search":_vm.device_search,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.device_search),callback:function ($$v) {_vm.device_search=$$v},expression:"device_search"}})],1)],1)],1)]},proxy:true}]),model:{value:(_vm.device_selected),callback:function ($$v) {_vm.device_selected=$$v},expression:"device_selected"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v("キャンセル")]),_c('v-btn',{attrs:{"color":"secondary","disabled":!_vm.edit_enable},on:{"click":_vm.click}},[(_vm.edit_flag)?_c('span',[_vm._v("変更")]):_c('span',[_vm._v("追加")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }