<template>
  <div>
    <v-data-table
      :headers="[
        { text: 'データID', value: 'id' },
        { text: '日時', value: 'date' },
        { text: '検出', value: 'detect', align: 'center' },
        { text: '画像', value: 'src', align: 'center' },
        { text: '', value: 'download', align: 'center' }
      ]"
      :sort-by="table.sort_by"
      :sort-desc="table.sort_desc"
      :items="
        Object.entries(Devicedata).map(([key, value]) => ({
          id: key,
          ...value
        }))
      "
      :item-key="'id'"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Search"
                append-icon="mdi-magnify"
                v-model="search"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:[`item.detect`]="{ item }">
        <detect-chip :detect="item.detect" />
      </template>
      <template v-slot:[`item.src`]="{ item }">
        <v-btn id="image_btn" icon plain color="black" @click="image_show(item)"
          ><v-icon>
            mdi-file-image-outline
          </v-icon></v-btn
        >
        <delete-btn
          v-if="is_delete"
          :item="item"
          @delete="$emit('delete', item)"
        />
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      @click:outside="close_dialog"
      :height="max_heigh"
      :width="max_widht"
    >
      <v-card>
        <v-card-title>
          {{ selected.date }}
          <detect-chip :detect="selected.detect" />
        </v-card-title>
        <v-img
          :src="image_dialog_src"
          contain
          :height="max_heigh"
          :width="max_widht"
        />
        <v-card-actions class="justify-end">
          <delete-btn
            v-if="is_delete"
            :item="selected"
            @delete="
              dialog = false;
              $emit('delete', selected);
            "
          />
          <v-btn id="image_btn" icon plain color="black" @click="download_image"
            ><v-icon>
              mdi-download
            </v-icon></v-btn
          >
          <v-btn
            plain
            icon
            @click="image_show(prev_data(selected.date))"
            :disabled="prev_data(selected.date) == undefined"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            plain
            icon
            @click="image_show(next_data(selected.date))"
            :disabled="next_data(selected.date) == undefined"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Toast ref="toast" />
  </div>
</template>

<script>
import DetectChip from "./detect_chip.vue";
import DeleteBtn from "./icon_btn_delete.vue";
import Toast from "./toast.vue";
export default {
  name: "device_data",
  components: { DetectChip, DeleteBtn, Toast },
  props: {
    Devicedata: {
      type: Object,
      default() {
        return {};
      }
    },
    image_dialog_src: {
      type: String,
      default: ""
    },
    is_delete: {
      type: Boolean,
      default: false
    }
  },
  watch: {},
  computed: {},
  data() {
    return {
      dialog: false,
      max_heigh: window.innerHeight / 2,
      max_widht: window.innerWidth / 2,
      table: {
        sort_by: ["date"],
        sort_desc: [true]
      },
      search: "",
      selected: {}
    };
  },
  methods: {
    image_show(data) {
      console.log("@image_show", data);
      this.$set(this, "selected", data);
      this.$emit("image_show", data);
      this.showDialog(true);
    },
    close_dialog() {
      this.showDialog(false);
    },
    next_data(date) {
      const next_datas = Object.entries(this.Devicedata)
        .map(([key, value]) => ({
          id: key,
          ...value
        }))
        .filter(x => x.date > date);

      next_datas.sort((a, b) => {
        if (a.date < b.date) {
          return -1;
        } else if (a.date > b.date) {
          return 1;
        }
        return 0;
      });
      return next_datas[0];
    },
    prev_data(date) {
      const prev_datas = Object.entries(this.Devicedata)
        .map(([key, value]) => ({
          id: key,
          ...value
        }))
        .filter(x => x.date < date);

      prev_datas.sort((a, b) => {
        if (a.date > b.date) {
          return -1;
        } else if (a.date < b.date) {
          return 1;
        }
        return 0;
      });
      return prev_datas[0];
    },
    showDialog(bool) {
      console.log(this.image_dialog_src);
      if (bool) {
        this.$set(this, "dialog", true);
      } else {
        this.$set(this, "dialog", false);
        this.$set(this, "selected", {});
        window.URL.revokeObjectURL(this.image_dialog_src);
      }
    },
    download_image() {
      this.$emit("download_image", {
        id: this.selected.id,
        blob: this.image_dialog_src
      });
    }
  }
};
</script>

<style></style>
