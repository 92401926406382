<template>
  <div id="manage_account">
    <HeaderFrame :nav_item="header_items" />
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                アカウント情報
                <span v-if="role == 0">：管理者アカウント</span>
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                    label="ID"
                    v-model="user_id"
                    disabled
                  ></v-text-field>
                  <v-text-field label="名前" v-model="user.name"></v-text-field>
                  <v-text-field
                    label="パスワード"
                    v-model="user.password"
                  ></v-text-field>
                  <v-text-field
                    label="Email"
                    v-model="user.email"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn @click="edit">保存</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Toast ref="toast" />
  </div>
</template>

<script>
import HeaderFrame from "../components/header.vue";
import Toast from "../components/toast.vue";
import constants from "../common/constants";
export default {
  name: "manage_account",
  components: {
    HeaderFrame,
    Toast
  },
  computed: {
    header_items() {
      return constants.mangage_header[this.role];
    },
    role() {
      return this.$store.state.user !== null ? this.$store.state.user.role : 1;
    }
  },
  data() {
    return {
      user_id: "",
      user: {
        name: "",
        password: "",
        email: "",
        role: 0
      }
    };
  },
  methods: {
    async edit() {
      console.log(this.user);
      const acceess_token = await this.$fnc.get_accesstoken(this.$db);
      const result = await this.$api.set_user(
        acceess_token,
        "",
        this.user.name,
        this.user.password,
        this.user.email,
        this.user.role
      );
      if (result) {
        this.$refs["toast"].showToast("変更しました");
      } else {
        this.$refs["toast"].showToast("変更に失敗しました");
      }
    }
  },
  async beforeMount() {
    setTimeout(async () => {
      const user = await this.$api.get_login_user(
        this.$store.getters.get_access_token
      );
      const user_id = Object.keys(user)[0];
      this.$set(this, "user_id", user_id);
      this.$set(this, "user", user[user_id]);
    }, 1000);
  }
};
</script>

<style></style>
