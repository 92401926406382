<template>
  <header>
    <v-app-bar :color="nav_color" dark app>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title
        style="cursor: pointer"
        @click="
          if ($route.name != 'manage_joinselect')
            $router.push({ name: 'manage_joinselect' });
        "
        >{{ title }}</v-toolbar-title
      >
      <v-tabs>
        <v-tab v-for="(item, index) in nav_item" :key="index" :to="item.to">
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-container>
        <v-row>
          <v-spacer />
          <LogoutBtn @logout="logout" />
        </v-row>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) of nav_item"
            :key="index"
            :to="item.to"
          >
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </header>
</template>

<script>
import constants from "../common/constants";
import LogoutBtn from "./logout_btn.vue";

export default {
  name: "headerFrame",
  props: {
    nav_item: {
      type: Array
    }
  },
  components: {
    LogoutBtn
  },
  data() {
    return {
      drawer: false,
      nav_color: constants.nav_color,
      title: constants.system_name
    };
  },
  methods: {
    logout() {
      if (this.$route.name != "SignIn") {
        // 初期化
        this.$store.commit("set_token", {});
        this.$store.commit("set_user", {});

        this.$router.push({ name: "SignIn" });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-toolbar__title {
  overflow: visible !important;
}

.v-app-bar__nav-icon {
  @include display_pc {
    display: none !important;
  }
}

.v-tabs {
  display: none;
  margin-left: 50px;

  @include display_pc {
    display: block !important;
  }
}
</style>
