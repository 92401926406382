<template>
  <v-btn
    id="add_btn"
    class="mx-2"
    small
    fab
    color="secondary"
    elevation="4"
    @click="$emit('add')"
    ><v-icon color="black">
      mdi-plus
    </v-icon></v-btn
  >
</template>

<script>
export default {
  name: "add_btn"
};
</script>

<style>
.add_btn {
  position: fixed;
  right: 50px;
  bottom: 50px;
  transition: 1s;
  opacity: 0.7;
}
</style>
