<template>
  <v-container id="groups_for_manage">
    <v-row>
      <v-col cols="12">
        <TableFrame
          ref="grouptable"
          :headers="table.headers"
          :item_key="table.item_key"
          :Dataset="groups"
          :add_flag="false"
          :delete_flag="true"
          @remove="remove"
          @row_click="
            data => {
              $router.push({
                name: 'group_info',
                params: { group_id: data.id }
              });
            }
          "
        />
      </v-col>
    </v-row>
    <Toast ref="toast" />
  </v-container>
</template>

<script>
import TableFrame from "./table.vue";
import Toast from "./toast.vue";
export default {
  name: "groups_for_manage",
  components: {
    TableFrame,
    Toast
  },
  data() {
    return {
      table: {
        headers: [
          { text: "ID", value: "id" },
          { text: "Name", value: "name" },
          { text: "所属ID", value: "organization_id" }
        ],
        item_key: "id"
      },

      groups: {}
    };
  },
  methods: {
    add_dialog(bool) {
      this.$refs["add_form"].showDialog(bool);
    },
    async set_data(data) {
      this.$set(this.groups, data.id, {
        name: data.name,
        address: data.address
      });
    },
    async remove() {
      const selected = this.$refs["grouptable"].get_selected();
      if (selected.length == 0) {
        this.$refs["toast"].showToast("一つ以上選択してください");
      } else {
        for (let data of selected) {
          console.log(data);
          const acceess_token = await this.$fnc.get_accesstoken(this.$db);
          const exe = async () => {
            const result = await this.$api.delete_group(acceess_token, data.id);
            if (result) {
              this.$delete(this.groups, data.id);
            }
          };
          exe();
        }
        this.$set(this.$refs["grouptable"], "selected", []);
      }
    }
  },
  async beforeMount() {
    const acceess_token = await this.$fnc.get_accesstoken(this.$db);
    const groups = await this.$api.get_group_list(acceess_token);
    this.$set(this, "groups", groups);
  }
};
</script>

<style></style>
