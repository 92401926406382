<template>
  <div id="manage_devices">
    <HeaderFrame :nav_item="header_items" />
    <v-main>
      <ManageDevices />
    </v-main>
  </div>
</template>

<script>
import HeaderFrame from "../components/header.vue";
import ManageDevices from "../components/manage_devices.vue";
import constants from "../common/constants";
export default {
  name: "manage_devices",
  components: {
    HeaderFrame,
    ManageDevices
  },
  computed: {
    header_items() {
      return this.mangage_header[0];
    }
  },
  data() {
    return {
      mangage_header: constants.mangage_header
    };
  }
};
</script>

<style></style>
