<template>
  <v-card id="group_info">
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="ID"
              disabled
              v-if="edit_flag"
              v-model="group.id"
            />
            <v-text-field
              label="Name"
              v-model="group.name"
              :disabled="!edit_enable"
            />
            <v-textarea
              label="Address"
              v-model="group.address"
              :disabled="!edit_enable"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="secondary"
        @click="$emit('click', group)"
        :disabled="!edit_enable"
      >
        <span v-if="edit_flag">変更</span>
        <span v-else>追加</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "group_info",
  components: {},
  props: {
    edit_flag: {
      type: Boolean,
      default: true
    },
    edit_enable: {
      type: Boolean,
      default: false
    },
    Group: {
      type: Object,
      default() {
        return {
          id: "",
          name: "",
          address: ""
        };
      }
    }
  },
  watch: {
    Group(v) {
      this.group = v;
    }
  },
  data() {
    return {
      group: this.Group
    };
  },
  methods: {
    init() {
      this.$set(this, "group", {
        id: "",
        name: "",
        address: ""
      });
    }
  }
};
</script>

<style></style>
