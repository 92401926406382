<template>
  <div id="group_devices">
    <HeaderFrame :nav_item="header_items" />
    <v-main>
      <GroupDevice :group_id="$route.params.group_id" />
    </v-main>
  </div>
</template>

<script>
import HeaderFrame from "../components/header.vue";
import GroupDevice from "../components/group_devices.vue";
import constants from "../common/constants";
export default {
  name: "group_devices",
  components: {
    HeaderFrame,
    GroupDevice
  },
  computed: {
    header_items() {
      return constants.group_header(this.$route.params.group_id);
    }
  },
  data() {
    return {};
  },
  beforeCreate() {}
};
</script>

<style></style>
